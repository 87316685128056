@import "../@scss/variable.scss";

.header-container {
  height: $header-height;

  input {
    &[type="text"] {
      // Fake Material Design Style
      padding: 0;
      transition: none;
      color: white;
      border-radius: 0;
      border: none;
      border-bottom: {
        color: white !important;
        width: 1px !important;
        style: solid !important;
      }
      background-color: transparent;

      &::placeholder {
        color: lightgray;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}
