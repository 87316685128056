@import "../@scss/variable.scss";
@import "../@scss/bazarr.scss";

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}

.notification-btn {
  &.new-item {
    &::after {
      position: absolute;
      background-color: red;
      content: "";
      border-radius: 50%;
      height: 6px;
      width: 6px;
      right: 10%;
      top: 10%;
    }
  }

  .dropdown-menu {
    max-height: 85vh;
    overflow-y: auto;
  }

  $content-width: 14rem;

  .notification-center-progress {
    width: $content-width;
    max-width: $content-width;

    .progress-name {
      word-wrap: break-word;
      white-space: normal;
    }

    .progress .progress-bar {
      text-shadow: -2px -2px 5px theme-color("primary"),
        2px -2px 5px theme-color("primary"), -2px 2px 5px theme-color("primary"),
        2px 2px 5px theme-color("primary");
      overflow: visible;
    }
  }

  .notification-center-notification {
    word-wrap: break-word;
    white-space: normal;
    width: $content-width;
    max-width: $content-width;
  }
}
