@import "../../@scss/variable.scss";

.custom-selector {
  .selector__control {
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0.25rem;
  }
  .selector__control--is-focused {
    border-color: var(--primary) !important;
  }
  .selector__menu {
    z-index: 1000;
  }

  .selector__option--is-focused {
    background-color: $theme-color-transparent;
    &:focus,
    &:active {
      background-color: $theme-color-less-transparent;
    }
  }

  .selector__option--is-selected {
    background-color: var(--primary);
    &:active {
      background-color: $theme-color-darked;
    }
  }
}
