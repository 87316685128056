@import "./variable.scss";

:root {
  .form-control {
    &:focus {
      outline-color: none !important;
      box-shadow: none !important;
      border-color: var(--primary) !important;
    }
  }
}

td {
  vertical-align: middle !important;
}

.dropdown-hidden {
  &::after {
    display: none !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.opacity-100 {
  opacity: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.of-hidden {
  overflow: hidden;
}

.of-auto {
  overflow: auto;
}

.vw-1 {
  width: 12rem;
}
